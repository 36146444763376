<template>
    <div id="org_manage">
        <jy-query ref="form" :model="form">
            <jy-query-item prop="orgNa" label="机构名称:">
                <el-input placeholder="请输入" v-model="form.orgNa"></el-input>
            </jy-query-item>
            <jy-query-item prop="pOrgNa" label="上级机构:">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.pOrgNa"></el-input>
                </div>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleSystemSettingsOrganizationManagementList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('scheduleSystemSettingsOrganizationManagementReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="addUdate()" v-if="btnexist('scheduleSystemSettingsOrganizationManagementInsert')">新增</el-button>
                    <!-- <el-button type="primary" @click="handleBatchDelete">批量删除</el-button>
                    <el-button type="primary">导出</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="dataList" @select="selectchange" @select-all="selectallchange">
            <jy-table-column type="index" label="序号" width="60" fixed="left"></jy-table-column>
            <jy-table-column prop="orgNa" label="机构名称" fixed="left" min-width="160"></jy-table-column>
            <jy-table-column prop="pOrgNa" label="上级机构" fixed="left" min-width="160"></jy-table-column>
            <jy-table-column label="机构类型" min-width="120">
                <template slot-scope="scope">
                    {{ scope.row.type | orgtype }}
                </template>
            </jy-table-column>
            <jy-table-column prop="createT" label="创建日期" min-width="160"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="160"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
        <add-update-org ref="addUpdateOrg" @updateList="onupdate"></add-update-org>
        <institutions-tree ref="institutions" :showType="['0','1']" @addInstitutions="addInstitutions" title="选择上级机构"></institutions-tree>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import addUpdateOrg from '@/components/pages/admin/systemSettings/organizationManagement/addUpdateOrg'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            statusList: [],
            form: {
                orgNa: '',
                pOrgId: '',
                pOrgNa: '',
            },
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],
            operateList: [],
            // 批量删除
            batchdeleteList: [],

            btnMenuId:'scheduleSystemSettingsOrganizationManagement',
        }
    },
    mixins:[btnMixins],
    components: {
        addUpdateOrg,
        institutionsTree,
    },
    filters: {
        orgtype(val) {
            if (val == '0') {
                return '机构'
            } else {
                return '车队'
            }
        },
    },
    created() {
        this.getList()
        this.setOperateList()
    },
    methods: {
        ...mapMutations(['resetTree']),
        setOperateList() {
            let l = [
                {
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.addUdate,
                    isShow:()=>{
                        return this.btnexist('scheduleSystemSettingsOrganizationManagementUpdate')
                    },
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                    isShow:()=>{
                        return this.btnexist('scheduleSystemSettingsOrganizationManagementDelete')
                    },
                },
                // {
                //     name: '查看',
                //     icon: 'el-icon-view',
                //     fun: this.handlecheck
                // }
            ]
            this.operateList = l
        },
        getList() {
            let option = {
                sysOrg:{
                    ...this.form,
                },
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            let url = '/sys/org/pageQuery'
            // console.log(option)
            this.$http.post(url, option,{isRequestParam:false}).then(({ detail }) => {
                detail.list.forEach(item=>{
                    item.createT = this.$util.formatTime(item.createT)
                })
                this.dataList = detail.list
                console.log(this.dataList)
                this.total = detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 更新表格
        onupdate() {
            this.getList()
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.form.pOrgId = ''
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        // 选择机构树
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        // 上级机构查询
        addInstitutions(data) {
            // console.log(data)
            this.form.pOrgId = data.orgId
            this.form.pOrgNa = data.orgNa
        },
        addUdate(option) {
            if (option) {
                this.$refs.addUpdateOrg.init(option, 'update')
            } else {
                this.$refs.addUpdateOrg.init()
            }
        },
        // 批量选择
        selectchange(selection) {
            // console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            // console.log(selection)
            this.batchdeleteList = selection
        },
        // 批量删除
        // handleBatchDelete() {
        //     let option = []
        //     this.batchdeleteList.forEach(item => {
        //         option.push(item.orgId)
        //     })
        //     let url = '/system/org/batchDeleteOrg'
        //     let o = {
        //         ordIds: option
        //     }
        //     this.$http.post(url, o).then(res => {
        //         this.$message({
        //             type: 'success',
        //             message: res.msg
        //         })
        //         this.getList()
        //     })
        // },
        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该机构?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let option = row.orgId
                    let url = '/sys/org/delete'
                    this.$http.post(url, option,{isRequestParam:false}).then(res => {
                        // console.log(res)
                        this.$message({
                            type: 'success',
                            message: res.msg,
                        })
                        this.resetTree()
                        this.getList()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
    },
}
</script>
<style scoped="organizationManagement" lang="scss">
#org_manage {
    .logo_img {
        width: 60px;
        height: 60px;

        i {
            font-size: 60px;
        }
    }
}
</style>
